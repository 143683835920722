<script>
  import { get, call } from 'vuex-pathify'
  import createGuardMixin from '@/helpers/createGuardMixin'
  import CartItemList from '@/components/checkout/CartItemList'
  import SoldOutItems from '@/components/checkout/SoldOutItems'
  import OrderSummary from '@/components/checkout/summary/OrderSummary'
  import CheckoutErrorModal from '@/components/checkout/CheckoutErrorModal'
  import ShippingModal from '@/components/marketplace/ShippingModal'

  const guardMixin = createGuardMixin(
    async ({ store, redirect }) => {
      if ((await store.dispatch('account/loggedInAsync')) === false) {
        return redirect({ name: 'AccountLogin' })
      }
      await store.dispatch('essentials/ensureFresh')
    },
    ['loggedIn']
  )

  export default {
    components: {
      CartItemList,
      OrderSummary,
      CheckoutErrorModal,
      SoldOutItems,
      ShippingModal,
    },
    mixins: [guardMixin],
    data() {
      return {
        openShippingModal: false,
      }
    },
    computed: {
      ...get('account', ['loggedIn', 'addresses']),
      ...get('essentialsCart', [
        'calculatingLineItems',
        'cartQuantity',
        'cartSavings',
        'errors',
        'isCartEmpty',
        'mobile',
        'nonSoldOutLineItems',
        'soldOutLineItems',
        'storeType',
        'subtotal',
      ]),
      checkoutUrl() {
        return { name: 'CheckoutShipping', params: { storeType: this.storeType } }
      },
      plpUrl() {
        return { name: 'EssentialsHome' }
      },
      soldOutVariants() {
        return this.soldOutLineItems.map(({ product }) => product.defaultVariant)
      },
    },
    watch: {
      cartQuantity() {
        if (this.cartQuantity) {
          this.reviewOrder()
        }
      },
    },
    created() {
      // added here so slider products don't change if something is added from the slider
      this.clearRemovedLineItems()
      this.initCart()
    },
    methods: {
      ...call('essentialsCart', [
        'clearRemovedLineItems',
        'clearErrors',
        'initCart',
        'removeCartItem',
        'reviewOrder',
        'toggleRemoveItem',
        'updateQuantity',
      ]),
      viewDetails(variant) {
        this.$navigate({
          name: 'EssentialsCartProduct',
          params: {
            productId: variant.productId,
            type: 'essentials',
          },
        })
      },
    },
  }
</script>

<template>
  <section class="pb-10 md:py-10">
    <Portal to="modal">
      <CheckoutErrorModal
        v-if="errors"
        store-type="essentials"
        :errors="errors"
        @clearErrors="clearErrors"
      />
    </Portal>
    <!---------------------- Start mobile view ---------------------->
    <template v-if="mobile">
      <!-- Subtotal text -->
      <template v-if="!isCartEmpty">
        <div class="px-6 pt-4 bg-dawn">
          <div class="flex justify-between">
            <div>
              <p>
                <span class="font-medium">Subtotal:</span>
                <span data-cy="cart-subtotal" class="font-semibold">{{
                  $formatPrice(subtotal, false)
                }}</span>
              </p>
              <p class="text-gray-dark text-sm"
                >({{ cartQuantity }} {{ cartQuantity > 1 ? 'items' : 'item' }})</p
              >
            </div>
            <div class="text-sm font-medium">Savings: {{ $formatPrice(cartSavings) }}</div>
          </div>
        </div>
        <!-- Continue to checkout button -->
        <div class="z-40 sticky top-0 px-6 py-3 bg-dawn">
          <BaseButton :to="checkoutUrl" :disabled="calculatingLineItems || isCartEmpty">
            Continue to checkout
          </BaseButton>
        </div>
      </template>
      <!-- Empty cart message -->
      <div v-else class="py-7 bg-dawn-lt3 flex flex-col flex-wrap content-center items-center">
        <BaseHeading size="h2-lg" tag="h2"> Your cart is empty. </BaseHeading>
        <div class="pt-6 px-4 w-full">
          <BaseButton :to="plpUrl"> Shop what's new&nbsp; </BaseButton>
        </div>
      </div>
      <!-- Loading spinner -->
      <BaseSpinner
        v-if="calculatingLineItems"
        overlay="absolute"
        overlay-color="dawn-lt3"
        class="z-50"
      />
    </template>
    <!---------------------- End mobile view ---------------------->

    <BaseContainer max-width="xl">
      <div class="layout mt-4 md:mt-8">
        <div>
          <div v-if="!mobile" class="-mt-4 flex items-center">
            <div class="flex-1 flex justify-between items-baseline">
              <div class="font-body">
                <BaseHeading size="h5" tag="h1">Your Cart</BaseHeading>
              </div>
              <BaseLinkStyled
                :to="plpUrl"
                color="gray"
                font-size="xs"
                icon-position="left"
                :icon-size="3"
              >
                <template v-slot:icon>
                  <IconArrowLeft />
                </template>
                <template v-slot>Back to shopping</template>
              </BaseLinkStyled>
            </div>
          </div>
          <div class="-mx-3 md:mx-0">
            <!-- Empty cart -->
            <div
              v-if="isCartEmpty && !mobile"
              class="mt-4 py-7 bg-dawn-lt3 flex flex-col flex-wrap content-center items-center"
            >
              <BaseHeading size="h2-lg" tag="h2"> Your cart is empty. </BaseHeading>
              <div class="pt-6 px-4 w-80">
                <BaseButton :to="plpUrl"> Shop what's new </BaseButton>
              </div>
            </div>
            <CartItemList
              :line-items="nonSoldOutLineItems"
              @toggleRemoveItem="toggleRemoveItem"
              @removeCartItem="removeCartItem"
              @updateQuantity="updateQuantity"
              @openShippingModal="openShippingModal = true"
              @viewDetails="viewDetails($event)"
            />
          </div>
          <div
            v-if="soldOutLineItems.length > 0"
            :class="`${nonSoldOutLineItems.length > 0 ? 'border-t border-gray-active' : ''}`"
          >
            <div class="my-4 flex px-2 text-xs leading-snug">
              <div class="mr-2 flex-shrink-0 text-green">
                <BaseIcon :size="6">
                  <IconInfo />
                </BaseIcon>
              </div>
              <div class="mt-2"> Sold out items have been removed from your order. </div>
            </div>
            <SoldOutItems type="essentials" :variants="soldOutVariants" />
          </div>
        </div>

        <!---------------------- Desktop view ---------------------->
        <template v-if="!mobile">
          <div />
          <div>
            <div class="p-4 rounded-sm bg-dawn">
              <OrderSummary v-if="!isCartEmpty" type="essentials" view="cart" />
              <!-- Continue to checkout button -->
              <BaseButton :to="checkoutUrl" :disabled="calculatingLineItems || isCartEmpty">
                Continue to checkout
              </BaseButton>
            </div>
          </div>
        </template>
      </div>
    </BaseContainer>
    <div v-if="isCartEmpty" class="my-8" />
    <ShippingModal
      :open="openShippingModal"
      type="essentials"
      @dismiss="openShippingModal = false"
    />
    <Portal to="productModal">
      <RouterView />
    </Portal>
  </section>
</template>

<style lang="postcss" scoped>
  .layout {
    @apply grid;

    @screen md {
      grid-template-columns: 628fr 76fr theme('spacing.82');
    }
  }
</style>
